export default function () {
	return {
		name: "数据",
		icon: "icon-report",
		path: null,
		children: [
			{
				name: "排行榜",
				icon: null,
				path: "/data/ranking"
			},
			{
				name: "线索数据",
				icon: null,
				path: "/data/clueData",
			}
		]
	}
}