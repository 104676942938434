export default function () {
	return {
		name: "客户",
		icon: "icon-user",
		path: null,
		children: [
			{
				name: "客户管理",
				icon: null,
				path: "/client/index",
				children: []
			},
		]
	}
}