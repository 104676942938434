import router from '@/router'
import store  from "@/store"

//白名单
const whiteList = ['/login']

router.beforeEach(async(to, from, next) => {
    if (store.getters.admin.token) {
        if (to.path === '/login') {
            next()
        } else {
            if (!store.getters.admin.role) {
                await store.dispatch('getAdminInfo')
            }
            const role = store.getters.admin.role;

            if (store.getters.admin.menuList.length === 0) {
                await store.dispatch('setMenuList')
            }
            const menuList = store.getters.admin.menuList;
            if (role === 1 || !to.meta.requireAuth || menuList.indexOf(to.path) >= 0) {
                next()
            } else {
                next({ path: '/error/NoPermission' })
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next({ path: '/login' })
        }
    }
})