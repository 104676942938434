export default function () {
	return {
		name: "订单",
		icon: "icon-order",
		path: null,
		children: [
			{
				name: "销售订单",
				icon: null,
				path: "/salesOrder/index",
				children: []
			},
			{
				name: "退换订单",
				icon: null,
				path: "/returnOrder/index",
				children: []
			}
		]
	}
}