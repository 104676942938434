<template>
    <div class="menu-wrap">
        <div class="logo">
            <img src="@/assets/image/logo.png">
        </div>
        <ul class="menu">
            <li class="item" v-for="(item, key) in menuList" :key="key" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)">
                <div class="main-nav">
                    <div class="menu-icon">
                        <i class="iconfont" :class="item.icon"></i>
                    </div>
                    <span class="menu-name">{{ item.name }}</span>
                    <span class="arrow">&gt;</span>
                </div>
                <div class="sub-nav-wrap">
                    <ul class="sub-nav">
                        <li v-for="(val, key) in item.children" :key="key" @click="handleMenuClick(val)">
                            <span>{{ val.name }}</span>
                            <!--
                            <router-link :to="{ path: val.path }"></router-link>
                            -->
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { menuList } from "@/router/nav";

const emit = defineEmits(['menu-click']);

const mouseOver = (event) => {
    event.currentTarget.className = 'item on';
};

const mouseLeave = (event) => {
    event.currentTarget.className = 'item';
};

const handleMenuClick  = (menuItem) => {
    emit('menu-click', menuItem);
};
</script>

<style scoped>
.menu-wrap .logo{position: absolute;z-index: 9999;}
.menu{list-style: none;margin: 0;padding: 46px 0 0 0;width: 100%;height: 100vh;}
.menu .item{position: relative;}
.menu .item.on{background: #157382;}
.menu .item.on .arrow{display: none;}
.menu .item.on .sub-nav-wrap{display: block;}
.menu .item .main-nav{position: relative;display: flex;flex-direction: column;justify-content: center;height: 80px;width: 100%;color: #fff;text-align: center;margin: 5px 0px;cursor: pointer;font-family: 新宋体, 宋体;}
.menu .item .main-nav .menu-icon{margin-bottom: 10px;}
.menu .item .main-nav .menu-icon i{font-size: 30px;}
.menu .item .main-nav .menu-name{font-weight: 700;font-size: 14px;}
.menu .item .main-nav .arrow{font-size: 12px;position: absolute;left: 75px;top: 55px;display: block;text-indent: 0;font-weight: bold;}
.menu .item .sub-nav-wrap{display: none;position: absolute;top: 0;left: 95px;width: 130px;border: 4px solid #c7c7c7;border-color: rgba(0,0,0,0.2);border-left: 0;border-top-right-radius: 4px;border-bottom-right-radius: 4px;z-index: 9999;background-color: #fff;}
.menu .item .sub-nav-wrap ul{padding: 10px 0 5px;line-height: 24px;}
.menu .item .sub-nav li{margin-bottom: 8px;z-index: 9999;}
.menu .item .sub-nav span{padding-left: 15px;display: block;color: #888;zoom: 1;cursor: pointer;}
.menu .item .sub-nav span:hover{background: #eee;}
</style>