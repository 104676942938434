import data from './data';
import clue from './clue';
import order from './order';
import sales from './sales';
import client from './client';
import product from './product';
import setting from './setting'

export const menuList = [
	order(),
	sales(),
	client(),
	product(),
	data(),
	clue(),
	setting()
]