export default function () {
	return {
		name: "设置",
		icon: "icon-setting",
		path: null,
		children: [
			{
				name: "部门管理",
				icon: null,
				path: "/department/index"
			},
			{
				name: "职员管理",
				icon: null,
				path: "/staff/index"
			},
			{
				name: "角色管理",
				icon: null,
				path: "/role/index"
			},
			{
				name: "权限管理",
				icon: null,
				path: "/admin/index"
			}
		]
	}
}