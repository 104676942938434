export default function () {
	return {
		name: "产品",
		icon: "icon-product",
		path: null,
		children: [
			{
				name: "产品管理",
				icon: null,
				path: "/product/index"
			},
		]
	}
}