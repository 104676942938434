import Cookies from 'js-cookie'

const tokenKey = 'access_token'
export const getToken = () => Cookies.get(tokenKey)
export const setToken = (token) => Cookies.set(tokenKey, token)
export const removeToken = () => Cookies.remove(tokenKey)

const menuKey = 'menu'
export const getMenuList = () => Cookies.get(menuKey)
export const setMenuList = (menuList) => Cookies.set(menuKey, menuList)
export const removeMenuList = () => Cookies.remove(menuKey)