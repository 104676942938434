<template>
	<div>
        <el-dialog :modelValue="visible" width="30%" title="修改密码" :before-close="handleClose" :close-on-click-modal="false" :lock-scroll="false">
            <el-form :model="form" :rules="rules" ref="formRef" label-width="80px">
                <el-form-item label="旧密码" prop="oldPwd">
                    <el-input v-model="form.oldPwd" type="password" />
                </el-form-item>
                <el-form-item label="新密码" prop="newPwd">
                    <el-input v-model="form.newPwd" type="password" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="handlerSubmit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue';
import { changePwd } from '@/api/admin';

defineProps({
    visible: Boolean
});

const emit = defineEmits(['update:visible']);

const { appContext } = getCurrentInstance();

const formRef = ref(null);

const form = reactive({
    oldPwd: '',
    newPwd: '',
});

const rules = reactive({
    oldPwd: [
        { required: true, message: '请输入旧密码', trigger: 'change' },
    ],
    newPwd: [
        { required: true, message: '请输入新密码', trigger: 'change' },
        { min: 6, max: 16, message: '密码长度必须在6-16位之间', trigger: 'change' }
    ],
});

const handleClose = () => {
    emit('update:visible', false);
};

const handlerSubmit = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            const data = {
                old_pwd: form.oldPwd,
                new_pwd: form.newPwd
            };
            changePwd(data).then((res) => {
                appContext.config.globalProperties.$message({ type: 'success', message: res.msg });
                emit('update:visible', false);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            return false;
        }
    })
};
</script>