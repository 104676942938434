import router from '@/router'
import { getAdminInfo, getAdminAuthMenu } from '@/api/admin'
import { getToken, setToken, removeToken, getMenuList, setMenuList, removeMenuList } from '@/utils/cookies'

export default {
	state: {
		name: '',
		role: '',
		token: getToken() || '',
		menuList: getMenuList() || [],
	},
	mutations: {
		SET_NAME(state, name) {
			state.name = name
		},
		SET_ROLE(state, role) {
			state.role = role
		},
		SET_TOKEN(state, token) {
			state.token = token
			setToken(token);
		},
		SET_MENULIST(state, menuList) {
			state.menuList = menuList
			setMenuList(menuList);
		}
	},
	actions: {
		setName({ commit }, name) {
			commit('SET_NAME', name)
		},
		setRole({ commit }, role) {
			commit('SET_ROLE', role)
		},
		setToken({ commit }, token) {
			commit('SET_TOKEN', token)
		},
		setMenuList({ commit }) {
			return new Promise((resolve) => {
				getAdminAuthMenu().then((res) => {
					let list = [];
					res.data.map((item) => {
						list.push(item.path);
					})
					commit('SET_MENULIST', list);
					resolve(res);
				})
			})
		},
		getAdminInfo({ commit }) {
			return new Promise((resolve) => {
				getAdminInfo().then((res) => {
					commit('SET_NAME', res.data.username)
					commit('SET_ROLE', res.data.role_id)
					resolve(res)
				})
			})
		},
		logout({ commit }) {
			removeToken();
			removeMenuList();
			commit('SET_ROLE', '');
			commit('SET_TOKEN', '');
			commit('SET_MENULIST', []);
			router.push("/login");
		}
	}
}