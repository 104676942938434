export default function () {
	return {
		name: "销售",
		icon: "icon-sale",
		path: null,
		children: [
			{
				name: "方案设计",
				icon: null,
				path: "/design/index"
			},
			{
				name: "快速报价",
				icon: null,
				path: "/quickoffer/index",
			},
			{
				name: "我的方案",
				icon: null,
				path: "/myQuote/index",
			}
		]
	}
}