export default function () {
	return {
		name: "线索",
		icon: "icon-clue",
		path: null,
		children: [
			{
				name: "我的线索",
				icon: null,
				path: "/clue/myClue"
			},
			{
				name: "离职交接",
				icon: null,
				path: "/clue/connectList"
			},
			{
				name: "预约考察",
				icon: null,
				path: "/clue/reserveList"
			},
			{
				name: "分配线索",
				icon: null,
				path: "/clue/unassignedClue"
			},
			{
				name: "跟进线索",
				icon: null,
				path: "/clue/followUpClue"
			},
			{
				name: "线索公海",
				icon: null,
				path: "/clue/publicClue"
			}
		]
	}
}